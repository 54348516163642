/**
 * Helper utilities for working with some stores, having to mapGetters and actions get's monotonous.
 * Also intellisense works on this utility class.
 */
export const storeUtils = {
  /**
   * A safe and managed wrapper around increasing and descresing the busy counter for a group. It supports async or sync workCallback functions.
   * The callback will cause the group to be busy, it automatically increments the counter and decrements it when the work is done.
   * @param {*} groupName
   * @param {*} workCallback
   * @returns
   */
  doBusyWork: async (context, groupName, workCallback) => {
    const store = getStore(context)
    if (!groupName) throw new Error('[isGroupBusy] groupName is required')
    return await store.dispatch('processGroupWork', {
      groupName,
      work: workCallback,
    })
  },
  /**
   * Checks if a group is busy.  If it has a busy counter greater than 0 then it is busy.
   * @param {*} groupName
   * @returns
   */
  isGroupBusy: (context, groupName) => {
    const store = getStore(context)
    if (!groupName) throw new Error('[isGroupBusy] groupName is required')
    store.getters.isWorkGroupBusy(groupName)
  },
  /**
   * Manually increases the busy counter for a group, if it's over 0 the group is busy.
   */
  increaseBusyCounter: (context, groupName) => {
    const store = getStore(context)
    if (!groupName)
      throw new Error('[increaseBusyCounter] groupName is required')
    store.commit('SET_GROUP_ISDOINGWORK', groupName)
  },
  /**
   * Manually decrease the busy counter for a group, if it hits 0 the group is no longer busy.
   * @param {*} groupName
   */
  descreaseBusyCounter: (context, groupName) => {
    if (!groupName)
      throw new Error('[descreaseBusyCounter] groupName is required')
    const store = getStore(context)
    store.commit('SET_GROUP_ISDONEDOINGWORK', groupName)
  },
  /**
   * Sets the busy counter for the specied group all the way to 0 (effectively clearing them all and any missed calls to decreaseBusyCounter)
   * @param {*} groupName
   */
  clearAllGroupBusy: (context, groupName) => {
    const store = getStore(context)
    store.commit('SET_GROUP_CLEAR_ALL', groupName)
  },
}

const getStore = (context) => {
  if (context && context.$store) {
    return context.$store
  }
  if (context && Object.hasOwn(context, '_committing')) {
    return context
  }
  throw new Error(
    'StoreUtils requires a valid context with a $store property be passed the utility functions.'
  )
}
