import { some } from 'lodash'

export const isAvailable = (
  user,
  stockRecordOrProduct,
  forcedPartnerId,
  validSiteIds
) => {
  const partnerId = forcedPartnerId || user.partner_id
  if (stockRecordOrProduct.active_stockrecords) {
    return !!stockRecordOrProduct.active_stockrecords.find(
      (sr) =>
        sr.available &&
        sr.partner === partnerId &&
        (!sr.restricted_to || user.memberships.includes(sr.restricted_to))
    )
  } else if (stockRecordOrProduct.available_site_partner_pairs) {
    // stockRecord came from elasticsearch Search index
    return some(validSiteIds, function (siteId) {
      return stockRecordOrProduct.available_site_partner_pairs.includes(
        `${siteId}-${partnerId}`
      )
    })
  } else {
    // stockRecord came from pdprecommendations
    return stockRecordOrProduct.partner_id === partnerId
  }
}

export const requestedNotification = (user, productId) => {
  if (!user.notifyme_products) {
    return false
  }

  if (!(productId in user.notifyme_products)) {
    return false
  }

  return user.notifyme_products[productId].is_active
}

export const calculatePercentageDiscount = (sr, price) => {
  let originalPrice = sr.price_excl_tax
  if (sr.retail_price) {
    originalPrice = Math.max(sr.retail_price, sr.price_excl_tax)
  }
  const difference = originalPrice - price
  const percentage = (difference / originalPrice) * 100
  return Math.round(percentage)
}

export const isTinyDiscount = (sr, price, tinyThreshold = 5) => {
  return calculatePercentageDiscount(sr, price) < tinyThreshold
}

export const incrementQuantityWidget = (amount, quantity) => {
  const newQuantity = quantity + amount
  if (newQuantity < 1) {
    return 1
  } else {
    quantity += amount
    return quantity
  }
}

export const disableNotificationService = async ($axios, productId, user) => {
  const notification = user.notifyme_products[productId]
  await $axios.patch(
    `/api/notifyme/notification-subscription/${notification.id}/`,
    {
      is_active: false,
    }
  )
  notification.is_active = false
}
