import { mapGetters } from 'vuex'
import { storeUtils } from '~/../common/utils/storeUtils'
import { incrementQuantityWidget, isAvailable } from '~/../common/utils/catalog'
import {
  calculateDisplayBottles,
  calculateDisplayPrice,
  categoryLabelText,
  showCategoryLabel,
} from '~/utils/product'
import { baseURL } from '~/../common/utils/url'

export const productCardMixin = {
  computed: {
    ...mapGetters(['pptoCart', 'pptoOrder']),
    adjustedQuantity() {
      if (this.quantityLimit) {
        return Math.min(this.quantity, this.quantityLimit)
      }
      return this.quantity
    },
    adjustedPrice() {
      return this.calculateDisplayPrice(this.adjustedQuantity)
    },
    showCategoryLabel() {
      return this.allowCategoryLabel && showCategoryLabel(this.product)
    },
    categoryLabelText() {
      const hideOffers = this.$route.path.includes('en-primeur')
      return categoryLabelText(this.product, hideOffers)
    },
    available() {
      return isAvailable(
        this.$auth.user,
        this.product,
        this.partnerId,
        this.$config.validSiteIds
      )
    },
    showErrors: {
      get() {
        return this.showErrors_ && this.errors.length > 0
      },
      set(val) {
        this.showErrors_ = val
        if (!val) {
          this.clearErrors()
        }
      },
    },
    showTieredDiscount() {
      return this.allowDeeperDiscount && this.product.discount_price_excl_tax
    },
    adjustedBottles() {
      return this.calculateDisplayBottles(this.adjustedQuantity)
    },
    extraSavingsHTML() {
      if (
        this.product.site_promo_eligible &&
        this.product.site_promo_discount_value &&
        this.product.site_promo_discount_type.toLowerCase() === 'percentage'
      ) {
        const minValue = Math.min(...this.product.site_promo_discount_value)
        const maxValue = Math.max(...this.product.site_promo_discount_value)
        let extraSavingsValue = `${minValue}% OFF<br> AT CHECKOUT`
        if (minValue !== maxValue) {
          extraSavingsValue = `${minValue}% TO ${maxValue}% OFF<br> AT CHECKOUT`
        } else if (this.product.site_promo_condition_type === 'Value') {
          extraSavingsValue = `${minValue} OFF $${this.product.site_promo_condition_value} OR<br> MORE AT CHECKOUT`
        }
        return extraSavingsValue
      }
      return ''
    },
    onPPTOPage() {
      return /^\/checkout\/add-to-order\/\d+\//.test(this.$route.path)
    },
    firstShipDate() {
      const fsDate =
        this.pptoOrder.partner_id === 22
          ? this.product.first_ship_date_ca
          : this.product.first_ship_date_ny
      return this.$moment(fsDate).format('MM/DD/YYYY')
    },
    showIcon() {
      // pending weather orders are 7 days from today
      if (
        Object.keys(this.pptoOrder).length &&
        this.firstShipDate &&
        this.pptoOrder.status.slug === 'pending_weather'
      ) {
        return this.$moment(this.firstShipDate).isAfter(
          this.$moment().add(7, 'd')
        )
      }
      // 7 days from the expected ship date
      if (
        Object.keys(this.pptoOrder).length > 0 &&
        this.firstShipDate &&
        this.pptoOrder.expected_ship_date
      ) {
        return this.$moment(this.firstShipDate).isAfter(
          this.$moment(this.pptoOrder.expected_ship_date)
        )
      }
      return false
    },
  },
  methods: {
    clearErrors() {
      this.errors = []
    },
    incrementQuantity(amount) {
      this.clearErrors()
      if (!this.quantityLimit || this.quantity + amount <= this.quantityLimit) {
        this.quantity = incrementQuantityWidget(amount, this.quantity)
      }
    },
    calculateDisplayPrice(qty) {
      return calculateDisplayPrice(this.product, qty)
    },
    async addToCart() {
      this.clearErrors()
      this.$emit('add-clicked')
      await storeUtils.doBusyWork(this, 'checkout', async () => {
        let addToCartType = 'addToCart'
        const addToCartData = {
          quantity: this.adjustedQuantity,
          product_id: this.product.product_id,
          source_url: baseURL() + this.$route.fullPath,
          ...this.extraAddToCartData,
        }
        if (addToCartData.is_topoff) {
          addToCartData.topoff_detail = this.product.topoff_detail
        }
        if (this.$route.path.match(/.*add-to-order\/([^/]+)\//i)) {
          addToCartType = 'addToPPTOCart'
          addToCartData.post_purchase_order = this.pptoCart.post_purchase_order
        } else if (this.$route.path.match(/.*en-primeur\//i)) {
          addToCartType = 'addToFuturesCart'
          addToCartData.isCheckout = true
        }
        try {
          await this.$store.dispatch(addToCartType, addToCartData)
        } catch (e) {
          this.showErrors_ = true
          if ([500, 504].includes(e.status) || !e.reason) {
            this.errors = ['Error adding product to cart.  Try again later.']
          } else if (typeof e.reason === 'object') {
            this.errors = e.reason.non_field_errors
          } else {
            this.errors = [e.reason]
          }
        }
        this.$emit('add-to-cart', addToCartData)
      })
    },
    setClickedProductID() {
      this.$store.dispatch('setProductIDInView', this.product.product_id)
    },
    calculateDisplayBottles(qty) {
      return calculateDisplayBottles(this.product, qty)
    },
    hideAllPrices() {
      this.seeAllPrices = false
      this.$nextTick(() => {
        this.$refs[`${this.product.product_id}-product-footer`].focus()
      })
    },
    showAllPrices() {
      this.seeAllPrices = true
      this.$nextTick(() => {
        this.$refs[
          `${this.product.product_id}-tiered-discounts`
        ].$el.children[0].focus()
      })
    },
  },
}
