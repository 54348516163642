<template>
  <li :data-product-id="product.product_id">
    <div
      class="narrative-card"
      :class="{ 'narrative-card-set': product.is_sampler }"
    >
      <div v-if="onPPTOPage && showIcon" class="product-header">
        <div class="category-spacer" />
        <div class="category-label">
          <span class="icon-shipping"></span>
          Available {{ firstShipDate }}
        </div>
      </div>
      <div v-else class="product-header">
        <div v-if="showCategoryLabel" class="category-spacer" />
        <div v-if="showCategoryLabel" class="category-label">
          {{ categoryLabelText }}
        </div>
      </div>
      <NarrativeProductCardDisplay
        :product="product"
        :extra-savings="extraSavingsHTML"
        :pdp-link="pdpLink"
        @detail-clicked="
          setClickedProductID()
          $emit('detail-clicked', product)
        "
      />
      <div
        v-if="product.is_sampler && product.num_bottles_in_sampler > 1"
        class="product-set"
      >
        {{ product.num_bottles_in_sampler }}-Bottle Set
      </div>
      <div :ref="`${product.product_id}-product-footer`" class="product-footer">
        <nuxt-link
          v-if="pdpLink"
          :prefetch="true"
          :to="product.url"
          @click.native="setClickedProductID()"
        >
          <span class="name">{{ product.title }}</span>
        </nuxt-link>
        <a
          v-else
          href="#"
          @click.prevent="
            setClickedProductID()
            $emit('detail-clicked', product)
          "
        >
          <span class="name">{{ product.title }}</span>
        </a>
        <RetailPrice
          v-if="product.show_retail_price"
          :show-label="false"
          :amt="Number(product.retail_price)"
        />
        <div class="pricing">
          <div class="price">
            <Price :amt="Number(adjustedPrice)" />
          </div>
          <TieredDiscountList
            v-show="seeAllPrices"
            :ref="`${product.product_id}-tiered-discounts`"
            :product="product"
            @close="hideAllPrices"
          />
          <div v-show="showTieredDiscount" class="count">
            {{ adjustedBottles }}
          </div>
          <button
            v-show="showTieredDiscount && !seeAllPrices"
            class="see-all"
            @click="showAllPrices"
          >
            See all prices
          </button>
        </div>
      </div>
      <div
        v-if="showErrors"
        class="product-footer pricing-detail error-box"
        @click="showErrors_ = false"
      >
        <button class="close">Close</button>
        <div>
          <ErrorList :errors="errors" />
        </div>
      </div>
      <div v-if="allowAddToCart" class="add-to-cart-options">
        <div class="quantity-group">
          <button
            class="quantity-change decrease"
            :disabled="!available"
            @click="incrementQuantity(-1)"
          >
            <span class="show-for-sr">Decrease</span>
          </button>
          <QuantityWidget
            :id="product.title"
            :quantity="adjustedQuantity"
            :quantity-limit="quantityLimit"
            type="text"
            :available="available"
            @clear-errors="clearErrors()"
            @set-quantity="quantity = arguments[0]"
          />
          <button
            v-if="quantityLimit === null || quantityLimit > adjustedQuantity"
            class="quantity-change increase"
            :disabled="!available"
            @click="incrementQuantity(1)"
          >
            <span class="show-for-sr">Increase</span>
          </button>
        </div>
        <div class="add-to-cart">
          <button
            v-if="available"
            :data-product-id="product.product_id"
            @click="addToCart"
          >
            {{ addButtonText }}
          </button>
          <button v-else disabled>Sold Out</button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { productCardMixin } from '~/mixins/productCardMixin'

export default {
  name: 'NarrativeProductCard',
  components: {
    ErrorList: () => import('~/../common/components/ErrorList.vue'),
    Price: () => import('~/../common/components/Price.vue'),
    QuantityWidget: () => import('~/components/Product/QuantityWidget'),
    NarrativeProductCardDisplay: () =>
      import('~/components/Product/Card/NarrativeProductCardDisplay.vue'),
    TieredDiscountList: () =>
      import('~/components/Product/Card/TieredDiscountList.vue'),
    RetailPrice: () => import('~/components/Product/Card/RetailPrice.vue'),
  },
  mixins: [productCardMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    partnerId: {
      type: Number,
      default: null,
    },
    allowAddToCart: {
      type: Boolean,
      default: false,
    },
    allowCategoryLabel: {
      type: Boolean,
      default: false,
    },
    pdpLink: {
      type: Boolean,
      default: true,
    },
    quantityLimit: {
      type: Number,
      default: null,
    },
    addButtonText: {
      type: String,
      default: 'Add to Cart',
    },
    allowDeeperDiscount: {
      type: Boolean,
      default: false,
    },
    extraAddToCartData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      seeAllPrices: false,
      showErrors_: true,
      errors: [],
      quantityFocus: false,
      quantity: 1,
    }
  },
}
</script>
